/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createAction,
	createSlice,
} from "@reduxjs/toolkit";

import {
	type UserPermissionsInfo,
} from "models/permissions/types";
import {
	type UserInfo,
} from "models/user-info/types";

interface ApplicationInfoState extends
	UserInfo,
	UserPermissionsInfo {}

const initialState: ApplicationInfoState = {
	applicationVersion: "",
	presenceType: null,
	userId: "",
	userName: "",
	userDataResponse: {},
	showDashboardEmployees: false,
	showDashboardEmployeesForHr: false,
	showNpaSync: false,
	showTmsTool: false,
	showCalendarManagementTool: false,
	shouldShowBanner: false,

	cpePermission: {
		canSeeProjectDashboard: false,
		canSeeTmsLog: false,
		canSeeEmployeesDashboard: false,
		canUploadNpaHours: false,
	},
	cpeBoard: {
		projectSummary: false,
		projectsEmployeeDaily: false,
		overtime: false,
		timePackages: false,
	},
	permissions: [],
};

const applicationInfoSlice = createSlice({
	name: "applicationInfo",
	initialState,
	reducers: {
		updateApplicationInfoState: (
			state,
			action: PayloadAction<ApplicationInfoState>,
		) => {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

const applicationInfoSliceReducer = applicationInfoSlice.reducer;

const {
	updateApplicationInfoState,
} = applicationInfoSlice.actions;
const getUserInfoFailure = createAction("applicationInfo/getUserInfoFailure");

export {
	applicationInfoSliceReducer,
	updateApplicationInfoState,
	getUserInfoFailure,
};
