/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	combineReducers,
	configureStore,
} from "@reduxjs/toolkit";
import {
	useDispatch,
} from "react-redux";

import multipleChoiceContainer from "components/common-components/multiple-choice/reduser";
import employeeDashboard from "components/employees-dashboard/reducer";
import journalData from "components/journal/reducer";
import {
	overtimeDetailsModalWindowReducer,
} from "components/overtime-details-modal-window/reducer";
import issueMappingData from "components/packages/issue-mapping/reducer";
import packageEditData from "components/packages/package-edit/reducer";
import packagesListData from "components/packages/packages-list/reducer";
import {
	projectNavigationReducer,
} from "components/projects-billing/components/header/project-navigation/store/reducer";
import projectsDashboard from "components/projects-billing/reducer";
import tmsLogData from "components/tms-log/reducer";
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	overtimeRequestDetailsReducer,
} from "pages/components/overtime-request-details/store/reducer";
import {
	spinnerReducer,
} from "pages/components/spinner/store/reducer";
import {
	employeesDashboardPageReducer,
} from "pages/employees-dashboard/store/reducer";
import {
	overtimeDashboardPageReducer,
} from "pages/overtime-dashboard/store/reducer";
import {
	projectDetailedPageReducer,
} from "pages/project-position-breakdown/store/reducer";
import {
	connectBannerReducer,
} from "pages/time-journal/components/connect-banner/store/reducer";
import {
	applicationInfoSliceReducer,
} from "store/slices/application-info/reducer";

import {
	googleAnalyticsMiddleware,
} from "./middleware/googleAnalytics";
import {
	promiseMiddleware,
} from "./middleware/promise";

const rootReducer = combineReducers({
	// Old-fashioned reducers.
	projectsDashboard,
	tmsLogData,
	journalData,
	multipleChoiceContainer,
	employeeDashboard,
	issueMappingData,
	packagesListData,
	packageEditData,

	// New-fashioned reducers.
	employeesDashboardPage: employeesDashboardPageReducer,
	overtimeDashboardPage: overtimeDashboardPageReducer,
	projectDetailedPage: projectDetailedPageReducer,
	projectNavigation: projectNavigationReducer,
	spinner: spinnerReducer,
	overtimeRequestDetails: overtimeRequestDetailsReducer,
	overtimeDetailsModalWindow: overtimeDetailsModalWindowReducer,
	applicationInfo: applicationInfoSliceReducer,
	connectBanner: connectBannerReducer,

	// API reducers.
	[reduxToolkitQueryApi.reducerPath]: reduxToolkitQueryApi.reducer,
});

type RootState = ReturnType<typeof rootReducer>;

// Adding and maintaining the return type manually would be difficult and fragile.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const getStore = (initialState?: Partial<RootState>) => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => {
			return getDefaultMiddleware({
				serializableCheck: false,
				immutableCheck: false,
			}).concat([
				promiseMiddleware,
				googleAnalyticsMiddleware,

				// API middleware.
				reduxToolkitQueryApi.middleware,
			]);
		},
		preloadedState: initialState,
		devTools: process.env.NODE_ENV !== "production",
	});
};

const store = getStore();

const dispatch = store.dispatch;

type Dispatch = typeof dispatch;

const useTypedDispatch = (): Dispatch => {
	return useDispatch<Dispatch>();
};

export {
	getStore,
	store,
	dispatch,
	useTypedDispatch as useDispatch,

	type RootState,
	type Dispatch,
};
