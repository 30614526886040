/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	permissionsApi,
} from "models/permissions/api";
import {
	userInfoApi,
} from "models/user-info/api";
import {
	type Dispatch,
} from "store";

import {
	getUserInfoFailure,
	updateApplicationInfoState,
} from "../reducer";

const getApplicationInfo = async (dispatch: Dispatch): Promise<void> => {
	const getUserInfoPromise = dispatch(
		userInfoApi.endpoints.getUserInfo.initiate(
			undefined,
		),
	);
	const getUserPermissionsInfoPromise = dispatch(
		permissionsApi.endpoints.getUserPermissionsInfo.initiate(
			undefined,
		),
	);

	try {
		const [
			userInfo,
			userPermissionsInfo,
		] = await Promise.all([
			getUserInfoPromise.unwrap(),
			getUserPermissionsInfoPromise.unwrap(),
		]);

		getUserInfoPromise.unsubscribe();

		getUserPermissionsInfoPromise.unsubscribe();

		dispatch(
			updateApplicationInfoState({
				...userInfo,
				...userPermissionsInfo,
			}),
		);
	} catch {
		dispatch(getUserInfoFailure());
	}
};

export {
	getApplicationInfo,
};
