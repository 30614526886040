/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	useSelector,
} from "react-redux";

import {
	type UserId,
	type UserName,
} from "models/user-info/types";
import {
	type RootState,
} from "store";

const getUserId = (state: RootState): UserId => {
	return state.applicationInfo.userId;
};

const useUserId = (): UserId => {
	return useSelector(getUserId);
};

const getUserName = (state: RootState): UserName => {
	return state.applicationInfo.userName;
};

const useUserName = (): UserName => {
	return useSelector(getUserName);
};

export {
	getUserId,
	useUserId,
	getUserName,
	useUserName,
};
