/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import camelCase from "lodash/camelCase";

import {
	getUserInfoFailure,
	updateApplicationInfoState,
} from "store/slices/application-info/reducer";

/** @type {import("@reduxjs/toolkit").Middleware} */
const googleAnalyticsMiddleware = () => {
	return (next) => {
		return (action) => {
			if (action?.type) {
				switch (action.type) {
					case updateApplicationInfoState.type: {
						/** @type {ReturnType<typeof updateApplicationInfoState>} */
						const typedAction = action;
						const userDataResponse = typedAction.payload.userDataResponse;

						const userDataWhatsNew = {
							...typedAction.payload,
						};

						const permissions = userDataWhatsNew.permissions.reduce(
							(permissionsObject, item) => {
								const permissionsItem = camelCase(item);

								return {
									...permissionsObject,
									[permissionsItem]: item,
								};
							},
							{},
						);

						delete userDataWhatsNew.userId;

						delete userDataWhatsNew.userName;

						delete userDataWhatsNew.applicationVersion;

						delete userDataWhatsNew.userDataResponse;

						delete userDataWhatsNew.permissions;

						delete userDataWhatsNew.cpePermission;

						delete userDataWhatsNew.cpeBoard;

						window.productTours = {
							userAttributes: {
								...userDataWhatsNew,
								...userDataResponse,
								...permissions,
							},
						};

						/* eslint-disable camelcase, @typescript-eslint/naming-convention */
						window.gtm_userdata = {
							user_id: typedAction.payload.userId,
							roles: typedAction.payload.permissions,
							region: userDataResponse.region,
							employment_status_name: userDataResponse.employmentStatusName,
							hired: userDataResponse.hiredDate,
							activity_tracking: userDataResponse.activityTracking,
							time_reporting_required: userDataResponse.timeReportingRequired,
							engagement_package: userDataResponse.engagementPackageId,
						};
						/* eslint-enable camelcase, @typescript-eslint/naming-convention */

						if (
							window.dataLayer
							&& Object.keys(window.gtm_userdata).length !== 0
						) {
							window.dataLayer.push({
								event: "user_data_load_complete",
							});
						}

						break;
					}

					case getUserInfoFailure.type: {
						window.dataLayer.push({
							event: "user_data_load_error",
							// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
							event_error_desc: "Timeout",
						});

						break;
					}

					default: {
						break;
					}
				}
			}

			return next(action);
		};
	};
};

export {
	googleAnalyticsMiddleware,
};
