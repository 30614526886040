/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	HttpStatusCode,
} from "axios";
import isFunction from "lodash/isFunction";
import noop from "lodash/noop";

import {
	svc,
} from "constants/services";

import Confirm from "../../components/common-components/confirm/Confirm";

const sessionRenewal = () => {
	void svc.uuiModals.show((props) => {
		return (
			<Confirm
				{...props}
				title="CONNECTION LOST"
				corfirmTitle="RETRY"
				message="You will be able to proceed working when the connection is restored.
				Please click RETRY to reconnect and proceed working."
				abort={noop}
			/>
		);
	})
		.then((action) => {
			if (!action) {
				window.history.go(0);
			}
		});
};

const promiseMiddleware = ({
	dispatch,
	getState,
}) => {
	return (
		(next) => {
			return (action) => {
				if (
					action.promise
					&& isFunction(action.promise)
				) {
					const thunk = action.promise(dispatch, getState);

					if (
						thunk.catch
						&& isFunction(thunk.catch)
					) {
						return thunk
							.catch((error) => {
								if (
									error.response
									&& error.response.status === HttpStatusCode.Unauthorized
								) {
									sessionRenewal();
								}

								return Promise.reject(error);
							});
					}

					return thunk;
				}

				return next(action);
			};
		}
	);
};

export {
	promiseMiddleware,
};
